
import * as Components from "./Pfactory"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "pfactory"
}

